import React from 'react';
import gsap from 'gsap';
import Styles from './integrationsSection.module.scss';
import integrationsData from '../../../data/integrationsData.json';
import { updateCursor, cursorPointer } from '../../../utils';

class IntegrationsSection extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedCategory: 'frontend',
		};
	}

	updateLogoOnTap(category) {
		this.setState({ selectedCategory: category });
	}

	updateLogo(index, mouseOverCheck) {
		gsap.to(`.logo${index}`, {
			opacity: mouseOverCheck ? 0 : 1,
			backgroundColor: mouseOverCheck ? 'rgba(38, 51, 95, 0)' : 'rgba(38, 51, 95, 1)',
			duration: 0.2,
		});
	}

	render() {
		const { selectedCategory } = this.state;

		const categories = [
			{
				category: 'frontend',
				title: 'Front End',
				icon: 'frontend'
			},
			{
				category: 'backend',
				title: 'Back End',
				icon: 'backend'
			},
			{
				category: 'mobile',
				title: 'Mobile',
				icon: 'mobile'
			},
			{
				category: 'cms',
				title: 'CMS',
				icon: 'cms'
			},
			{
				category: 'design-and-ops',
				title: 'Design & Ops',
				icon: 'design-and-ops'
			},
		];

		const filteredIntegrations = integrationsData.filter(
			(integration) => integration.category === selectedCategory
		);

		return (
			<div id="integrationsSection" className={`text-center ${Styles.integrationsSection}`} onMouseEnter={() => updateCursor('white')} >
				<h1 className="text-center mb-5 pb-2">Technology Solutions We Leverage</h1>
				<div className={Styles.categoryButtons}>
					{categories.map((category) => (
						<div
							onMouseEnter={() => cursorPointer(true)}
							onMouseLeave={() => cursorPointer(false)}
							key={category.icon}
							className={
								this.state.selectedCategory === category.category
									? Styles.categoryTabActive
									: Styles.categoryTab
							}
							onClick={() => this.updateLogoOnTap(category.icon)}>
							<img
								src={require(`../../../images/integrations/categoryIcons/${category.icon}.svg`)}
								alt={category.icon} />
							<h6 className={Styles.categoryName}>{category.title}</h6>
						</div>
					))}
				</div>
				<div className={Styles.contentContainer}>
					{filteredIntegrations.map((integration, index) => {
						const logoAssets = require.context('../../../images/integrations', true, /\.svg$/);
						const integrationLogo = logoAssets(`./${integration.logo}-primary.svg`);
						const integrationHoverLogo = logoAssets(`./${integration.logo}-hover.svg`);

						return (
							<div key={index} className={Styles.logoContainer} data-toggle="tooltip" title={integration.name}>
								<img
									key={`${index}-hover`}
									className={Styles.hoverLogo}
									src={integrationHoverLogo}
									alt={`${integration.name}logo`} />
								<img
									key={index}
									className={`${`logo${index}`} ${Styles.logo}`}
									onMouseEnter={() => this.updateLogo(index, true)}
									onClick={() => this.updateLogoOnTap(integration.category)}
									onMouseOut={() => this.updateLogo(index, false)}
									src={integrationLogo}
									alt={`${integration.name}logo`} />
							</div>
						);
					})}
				</div>
				<img
					className={Styles.backgroundLogo}
					src={require(`../../../images/integrations/categoryIcons/${selectedCategory}.svg`)}
					alt={`${selectedCategory} background logo`} />
			</div>
		);
	}
}

export { IntegrationsSection };
