import React from 'react';
import Lottie from 'lottie-web';
import {
	BalancingSecurity,
	MobileDrivenDesign,
	CollageBoxOne,
	CollageBoxTwo,
	CollageBoxThree,
	CollageBoxFour,
	CollageBoxFive,
	ElevatingBrandPresence,
	EnhancingUserEngagement,
	StripeIntegration
} from '../../../images/treasure';
import {
	SecuritySectionLock,
	CmsLottie,
	CmsBackgroundLottie,
	ShootingStarLottie,
	StripeSecure
} from '../../../lotties/treasure';
import { CtaSection } from '../components/ctaSection';
import { CaseStudyHeroSection } from '../../caseStudyHeroSection';
import { TwoColumnGrid } from '../../../layouts/twoColumnGrid/twoColumnGrid';
import { updateCursor } from '../../../utils/index';
import PlanetTreasure from '../../../images/treasure/planetTreasureCtaSection.svg';
import SectionsData from '../sectionsData/treasure.json';
import Styles from './treasure.module.scss';

class TreasureScreen extends React.Component {
	componentDidMount() {
		this.loadLottieAnimation('#cmsLottieContainer', CmsLottie);
		this.loadLottieAnimation('#cmsBackgroundLottieContainer', CmsBackgroundLottie);
		this.loadLottieAnimation('#balancingSecurityLottieContainer', SecuritySectionLock);
		this.loadLottieAnimation('#shootingStarLottieContainer', ShootingStarLottie);
		this.loadLottieAnimation('#stripeIntegrationLottieContainer', StripeSecure);
	}

	loadLottieAnimation = (container, animationData) => {
		Lottie.loadAnimation({
			container: document.querySelector(container),
			renderer: 'svg',
			loop: true,
			autoplay: true,
			animationData
		});
	};

	render() {
		const {
			heroSection,
			balancingSecurity,
			mobileDrivenDesign,
			elevatingBrandPresence,
			noCodeSiteManager,
			enhancingUserEngagement,
			stripeIntegration
		} = SectionsData;

		return (
			<main className={Styles.mainContainer}>
				<CaseStudyHeroSection
					starsBackgroundHeader= {heroSection.starsBackgroundHeader}
					caseStudyHeading= {heroSection.caseStudyHeading}
					link= {heroSection.link}
					sloganHeading= {heroSection.sloganHeading}
					caseStudyText= {heroSection.caseStudyText} />
				<TwoColumnGrid
					gridStyles={Styles.securityGridContainer}
					isBackgroundWhite={true}
					revertOnMobile={true}
					leftColumn={
						<article className={Styles.textSection}>
							<h4 className="font-weight-bold">{balancingSecurity.title}</h4>
							<p className="mt-3">
								{balancingSecurity.paragraphOne}
								<br /><br />
								{balancingSecurity.paragraphTwo}
							</p>
						</article>
					}
					rightColumn={
						<div className={Styles.lottieColumn} onMouseEnter={() => updateCursor('white')}>
							<div id="balancingSecurityLottieContainer" className={Styles.balancingSecurityLottie}/>
							<img src={BalancingSecurity} alt="Balancing security and convenience" width="100%" />
						</div>
					} />
				<TwoColumnGrid
					gridStyles={Styles.mobileDrivenDesignGridContainer}
					leftColumn={
						<img src={MobileDrivenDesign} alt="Mobile driven design" width="100%" height="100%" />
					}
					rightColumn={
						<article className={Styles.textSection}>
							<h4 className="font-weight-bold">{mobileDrivenDesign.title}</h4>
							<p className="mt-3">
								{mobileDrivenDesign.paragraphOne}
								<br /><br />
								{mobileDrivenDesign.paragraphTwo}
							</p>
						</article>
					} />
				<TwoColumnGrid
					gridStyles={Styles.brandPresenceGridContainer}
					isBackgroundWhite={true}
					revertOnMobile={true}
					leftColumn={
						<article className={Styles.textSection}>
							<h4 className="font-weight-bold">{elevatingBrandPresence.title}</h4>
							<p className="mt-3">
								{elevatingBrandPresence.paragraphOne}
								<br /><br />
								{elevatingBrandPresence.paragraphTwo}
							</p>
						</article>
					}
					rightColumn={
						<img
							src={ElevatingBrandPresence}
							className={Styles.mediaContainer}
							alt="Modern UI design"
							width="100%"
							onMouseEnter={() => updateCursor('white')}
							onMouseLeave={() => updateCursor('blue')} />
					} />
				<TwoColumnGrid
					gridStyles={Styles.noCodeGridContainer}
					leftColumn={
						<div className={Styles.mediaContainer}>
							<div className={Styles.lottieColumn}>
								<div id="cmsLottieContainer" className={Styles.cmsLottie} />
								<div id="cmsBackgroundLottieContainer" className={Styles.cmsBackgroundLottie} />
							</div>
						</div>
					}
					rightColumn={
						<article className={Styles.textSection}>
							<h4 className="font-weight-bold">{noCodeSiteManager.title}</h4>
							<p className="mt-3">
								{noCodeSiteManager.paragraphOne}
								<br /><br />
								{noCodeSiteManager.paragraphTwo}
							</p>
						</article>
					} />
				<TwoColumnGrid
					gridStyles={Styles.userEngagementGridContainer}
					isBackgroundWhite={true}
					revertOnMobile={true}
					leftColumn={
						<article className={Styles.textSection}>
							<h4 className="font-weight-bold">{enhancingUserEngagement.title}</h4>
							<p className="mt-3">
								{enhancingUserEngagement.paragraphOne}
								<br /><br />
								{enhancingUserEngagement.paragraphTwo}
							</p>
						</article>
					}
					rightColumn={
						<div
							className={Styles.mediaContainer}
							onMouseEnter={() => updateCursor('white')}
							onMouseLeave={() => updateCursor('blue')}>
							<div className={Styles.shootingStarsLottieColumn}>
								<div id="shootingStarLottieContainer" className={Styles.shootingStarsLottie} />
								<img
									src={EnhancingUserEngagement}
									className={Styles.userEngagementSection}
									alt="Enhancing user engagement"
									width="100%" />
							</div>
						</div>
					} />
				<TwoColumnGrid
					gridStyles={Styles.stripeGridContainer}
					leftColumn={
						<div className={Styles.mediaContainer}
							onMouseEnter={() => updateCursor('blue')}
							onMouseLeave={() => updateCursor('white')}>
							<div className={Styles.stripeLottieColumn}>
								<div id="stripeIntegrationLottieContainer" className={Styles.stripeIntegrationLottie} />
								<img src={StripeIntegration} className={Styles.stripe} alt="Stripe Integration" width="100%" />
							</div>
						</div>
					}
					rightColumn={
						<article className={Styles.textSection}>
							<h4 className="font-weight-bold">{stripeIntegration.title}</h4>
							<p className="mt-3">
								{stripeIntegration.paragraphOne}
								<br /><br />
								{stripeIntegration.paragraphTwo}
							</p>
						</article>
					} />
				<div className={Styles.collageContainer} onMouseEnter={() => updateCursor('white')}>
					<div
						className={Styles.collageBoxOne}
						onMouseEnter={() => updateCursor('blue')}
						onMouseLeave={() => updateCursor('white')}>
						<img src={CollageBoxOne} alt="Collage box one" />
					</div>
					<div
						className={Styles.collageBoxTwo}
						onMouseEnter={() => updateCursor('blue')}
						onMouseLeave={() => updateCursor('white')}>
						<img src={CollageBoxTwo} alt="Collage box two" />
					</div>
					<div
						className={Styles.collageBoxThree}
						onMouseEnter={() => updateCursor('blue')}
						onMouseLeave={() => updateCursor('white')}>
						<img src={CollageBoxThree} alt="Collage box three" />
					</div>
					<div
						className={Styles.collageBoxFour}
						onMouseEnter={() => updateCursor('blue')}
						onMouseLeave={() => updateCursor('white')}>
						<img src={CollageBoxFour} alt="Collage box four" />
					</div>
					<div
						className={Styles.collageBoxFive}
						onMouseEnter={() => updateCursor('blue')}
						onMouseLeave={() => updateCursor('white')}>
						<img src={CollageBoxFive} alt="Collage box five" />
					</div>
				</div>
				<CtaSection planetImage={PlanetTreasure} />
			</main>
		);
	}
}

export { TreasureScreen };
