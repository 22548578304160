import React from 'react';
import gsap from 'gsap';
import tinycolor from 'tinycolor2';
import { Link, Navigate } from 'react-router-dom';
import { cursorPointer } from '../../utils';
import TagsData from '../../data/tags.json';
import Styles from './projectCard.module.scss';

class ProjectCard extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			hover: false,
			navigateURL: '',
			tagColors: {}
		};
	}

	updateTagColors = () => {
		const tagColors = {};
		const tagsData = TagsData.map((tagCategory) => (
			tagCategory.tags.map((tag) => tag)
		)).flatMap((innerArray) => innerArray);

		tagsData.forEach((tagData) => {
			const tagColor = tinycolor(tagData.color);
			tagColors[tagData.name] = tagColor;
		});

		this.setState({ tagColors });
	};

	projectCardHover(mouseOverCheck) {
		const backgroundColor = tinycolor(`#${this.props.color}`).lighten().toString().slice(1, 7);
		const red = parseInt(backgroundColor.substr(0, 2), 16);
		const green = parseInt(backgroundColor.substr(2, 2), 16);
		const blue = parseInt(backgroundColor.substr(4, 2), 16);
		const colorDifference = ((red * 299) + (green * 587) + (blue * 114)) / 1000;
		const textColor = (colorDifference < 180) ? ('#fff') : ('#5a5b5c');

		this.setState({ hover: mouseOverCheck });

		cursorPointer(mouseOverCheck);

		if (document.getElementById('projectsSectionHeading')) {
			document.getElementById('projectsSectionHeading').style.color = (mouseOverCheck) ? (textColor) : ('#5a5b5c');
		}

		if (mouseOverCheck) {
			cursorPointer(true);
		} else {
			cursorPointer(false);
		}

		this.props.setBackgroundLogo((mouseOverCheck) ? (require.context('../../images/projects', true, /\.png$/)(`./${this.props.name}-hover.png`)) : (''), this.props.name);

		gsap.to('#projectsSection', {
			background: (mouseOverCheck) ? (`#${backgroundColor}`) : ('#fff'),
			duration: 0.6
		});

		gsap.to(`.${this.props.name}ProjectCard`, {
			background: (mouseOverCheck) ? (`#${this.props.color}`) : ('#fff'),
			color: (mouseOverCheck) ? ('#fff') : ('#5a5b5c'),
			duration: 0
		}, 0);

		if (this.props.url) {
			gsap.to(`.${this.props.name}ProjectCard a`, {
				color: (mouseOverCheck) ? ('#fff') : ('#5a5b5c'),
				duration: 0
			}, 0);
		}
	}

	componentDidMount() {
		this.updateTagColors();
	}

	render() {
		const projectAssets = require.context('../../images/projects', true, /\.png$/);

		const tagButtons = this.props.tags?.map((tag, index) => {
			const tagColor = this.state.tagColors[tag];
			const backgroundColor = tagColor && tagColor.toString();

			return (
				<button
					id={`tagBackground--${tag}`}
					className={Styles.tagCard}
					key={index}
					style={{ backgroundColor }}
				>
					{tag}
				</button>
			);
		});

		const {
			index, url, name, description
		} = this.props;

		let cardSize;

		if (index) {
			cardSize = index < 8 ? Styles.fullCard : Styles.halfCard;
		} else {
			cardSize = Styles.fullCard;
		}

		return (
			<>
				{this.state.navigateURL !== '' && <Navigate to={this.state.navigateURL} />}
				<div className={Styles.projectCardContainer} onClick={() => {
					this.setState({ navigateURL: url ? `/projects/${url}` : '/coming-soon' });
					cursorPointer(false);
				}}>
					<div
						className={`pointer ${`${name}ProjectCard`} ${Styles.cardContainer} ${cardSize}`}
						onMouseEnter={() => { this.projectCardHover(true); }}
						onMouseLeave={() => { this.projectCardHover(false); }}>
						<div className={Styles.projectLogoContainer}>
							<img
								id={`${name}ProjectLogo`}
								className={Styles.projectLogo}
								src={(this.state.hover)
									? (projectAssets(`./${name}-hover.png`))
									: (projectAssets(`./${name}-primary.png`))}
								alt={`${name}logo`}/>
						</div>
						<div className={`${Styles.projectDescriptionContainer} ${cardSize}`}>
							<p className={Styles.projectText}>{description}</p>
						</div>
						<div className={`${Styles.projectTagsContainer} ${url ? cardSize : ''}`}>
							{tagButtons}
						</div>
						{url
						&& <div className={cardSize === Styles.fullCard
							? Styles.cardCTASection
							: Styles.cardHoverCTASection}>
							<Link to={`/projects/${url}`} className={cardSize === Styles.fullCard ? Styles.cardCTAText : Styles.cardHoverCTAText}>
								{cardSize === Styles.fullCard ? 'Read the Case Study' : 'Learn More'}
							</Link>
						</div>
						}
					</div>
				</div>
			</>
		);
	}
}

export { ProjectCard };
