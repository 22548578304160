import React from 'react';
import Lottie from 'lottie-web';
import {
	CalendarLottie,
	ContentBackgroundLottie,
	MultiplePlatformsLottie,
	NavigationRobotLottie
} from '../../../lotties/rekd';
import {
	AdminConsole,
	Community,
	Collage,
	DarkMode,
	NavigateContent,
	Personalized,
	PlanetRekd,
	PositiveDigitalSpace,
	RewardingExploration,
	TranscendBackground,
	Trivia
} from '../../../images/rekd';
import { CaseStudyHeroSection } from '../../caseStudyHeroSection';
import { CtaSection } from '../components/ctaSection';
import { ImageSwiper } from '../../imageSwiper';
import SectionsData from '../sectionsData/rekd.json';
import { TwoColumnGrid } from '../../../layouts/twoColumnGrid/twoColumnGrid';
import { updateCursor } from '../../../utils';
import Styles from './rekd.module.scss';

class RekdScreen extends React.Component {
	componentDidMount() {
		this.loadLottieAnimation('#navigationRobotLottieContainer', NavigationRobotLottie, 0.65);
		this.loadLottieAnimation('#contentBackgroundLottieContainer', ContentBackgroundLottie, 0.65);
		this.loadLottieAnimation('#calendarLottieContainer', CalendarLottie);
		this.loadLottieAnimation('#multiplePlatformsLottieContainer', MultiplePlatformsLottie);
	}

	loadLottieAnimation = (container, animationData, speed = 1) => {
		const animate = Lottie.loadAnimation({
			container: document.querySelector(container),
			renderer: 'svg',
			loop: true,
			autoplay: true,
			animationData
		});

		animate.setSpeed(speed);
	};

	render() {
		const {
			adminConsole,
			calendar,
			contentExploration,
			contentModeration,
			contentPersonalization,
			darkUI,
			heroSection,
			helpUsersNavigate,
			multiplePlatforms,
			thrivingCommunity,
			trivia
		} = SectionsData;

		return (
			<main className={Styles.mainContainer}>
				<CaseStudyHeroSection
					starsBackgroundHeader={heroSection.starsBackgroundHeader}
					caseStudyHeading={heroSection.caseStudyHeading}
					link={heroSection.link}
					sloganHeading={heroSection.sloganHeading}
					caseStudyText={heroSection.caseStudyText} />
				<TwoColumnGrid
					gridStyles={Styles.navigateContentGridContainer}
					isBackgroundWhite={true}
					leftColumn={
						<article className={`${Styles.textSection} py-lg-4`}>
							<h4 className="font-weight-bold">{helpUsersNavigate.title}</h4>
							<p className="mt-3">{helpUsersNavigate.paragraphOne}</p>
							<p className="mt-3">{helpUsersNavigate.paragraphTwo}</p>
						</article>
					}
					rightColumn={
						<div onMouseEnter={() => updateCursor('white')} className={Styles.navigateContentLottieColumn}>
							<div
								id="navigationRobotLottieContainer"
								className={Styles.navigateContentLottie} />
							<div
								id="contentBackgroundLottieContainer"
								className={Styles.contentBackgroundLottie} />
							<img
								src={NavigateContent}
								className={Styles.navigateContentBackground}
								alt="Navigating Through Content"
								width="100%"
								height="100%"
								loading="lazy" />
						</div>
					} />
				<TwoColumnGrid
					gridStyles={Styles.contentExplorationGridContainer}
					revertOnMobile={true}
					leftColumn={
						<img
							src={RewardingExploration}
							alt="Making Exploration Rewarding"
							width="100%"
							height="100%"
							loading="lazy" />
					}
					rightColumn={
						<article className={`${Styles.textSection} py-lg-4`}>
							<h4 className="font-weight-bold">{contentExploration.title}</h4>
							<p className="mt-3">{contentExploration.paragraphOne}</p>
							<p className="mt-3">{contentExploration.paragraphTwo}</p>
						</article>
					} />
				<TwoColumnGrid
					gridStyles={`${Styles.paddedContainer} ${Styles.communityGridContainer}`}
					isBackgroundWhite={true}
					leftColumn={
						<article className={`${Styles.textSection} py-lg-4`}>
							<h4 className="font-weight-bold">{thrivingCommunity.title}</h4>
							<p className="mt-3">{thrivingCommunity.paragraphOne}</p>
							<p className="mt-3">{thrivingCommunity.paragraphTwo}</p>
						</article>
					}
					rightColumn={
						<img
							src={Community}
							alt="Creating Thriving Community"
							onMouseEnter={() => updateCursor('white')}
							onMouseLeave={() => updateCursor('blue')}
							width="100%"
							height="100%"
							loading="lazy" />
					} />
				<TwoColumnGrid
					gridStyles={`${Styles.paddedContainer} ${Styles.calendarGridContainer}`}
					revertOnMobile={true}
					leftColumn={
						<div
							className={Styles.calendarLottieColumn}
							onMouseEnter={() => updateCursor('blue')}
							onMouseLeave={() => updateCursor('white')}>
							<div id="calendarLottieContainer" className={Styles.calendarLottie} />
						</div>
					}
					rightColumn={
						<article className={Styles.textSection}>
							<h4 className="font-weight-bold mt-lg-4">{calendar.title}</h4>
							<p className="mt-3">
								{calendar.paragraphOne}
								<br /><br />
								{calendar.paragraphTwo}
							</p>
						</article>
					} />
				<TwoColumnGrid
					gridStyles={`${Styles.paddedContainer} ${Styles.contentGridContainer}`}
					isBackgroundWhite={true}
					leftColumn={
						<article className={`${Styles.textSection} py-lg-4`}>
							<h4 className="font-weight-bold">{contentPersonalization.title}</h4>
							<p className="mt-3">{contentPersonalization.paragraphOne}</p>
						</article>
					}
					rightColumn={
						<img
							src={Personalized}
							alt="Delivering Personalized Content"
							onMouseEnter={() => updateCursor('white')}
							onMouseLeave={() => updateCursor('blue')}
							width="100%"
							height="100%"
							loading="lazy" />
					} />
				<TwoColumnGrid
					gridStyles={`${Styles.paddedContainer} ${Styles.darkModeGridContainer}`}
					revertOnMobile={true}
					leftColumn={
						<img
							src={DarkMode}
							alt="Reducing Eye Strain With Dark UI"
							width="100%"
							height="100%"
							loading="lazy" />
					}
					rightColumn={
						<article className={`${Styles.textSection} py-lg-4`}>
							<h4 className="font-weight-bold">{darkUI.title}</h4>
							<p className="mt-3">{darkUI.paragraphOne}</p>
							<p className="mt-3">{darkUI.paragraphTwo}</p>
						</article>
					} />
				<TwoColumnGrid
					gridStyles={`${Styles.paddedContainer} ${Styles.triviaGridContainer}`}
					isBackgroundWhite={true}
					leftColumn={
						<article className={`${Styles.textSection} py-lg-4`}>
							<h4 className="font-weight-bold">{trivia.title}</h4>
							<p className="mt-3">{trivia.paragraphOne}</p>
							<p className="mt-3">{trivia.paragraphTwo}</p>
						</article>
					}
					rightColumn={
						<img
							src={Trivia}
							alt="Trivia"
							onMouseEnter={() => updateCursor('white')}
							onMouseLeave={() => updateCursor('blue')}
							width="100%"
							height="100%"
							loading="lazy" />
					} />
				<TwoColumnGrid
					gridStyles={Styles.paddedContainer}
					revertOnMobile={true}
					leftColumn={
						<img
							src={PositiveDigitalSpace}
							alt="Positive Digital Space"
							onMouseEnter={() => updateCursor('blue')}
							onMouseLeave={() => updateCursor('white')}
							width="100%"
							height="100%"
							loading="lazy" />
					}
					rightColumn={
						<article className={`${Styles.textSection} py-lg-4`}>
							<h4 className="font-weight-bold">{contentModeration.title}</h4>
							<p className="mt-3">{contentModeration.paragraphOne}</p>
						</article>
					} />
				<TwoColumnGrid
					gridStyles={`${Styles.paddedContainer} ${Styles.multiplePlatformsGridContainer}`}
					isBackgroundWhite={true}
					leftColumn={
						<article className={Styles.textSection}>
							<h4 className="font-weight-bold">{multiplePlatforms.title}</h4>
							<p className="mt-3">{multiplePlatforms.paragraphOne}</p>
							<p className="mt-3">{multiplePlatforms.paragraphTwo}</p>
						</article>
					}
					rightColumn={
						<div className={Styles.multiplePlatformsLottieColumn}>
							<div
								id="multiplePlatformsLottieContainer"
								className={Styles.multiplePlatformsLottie}
								onMouseEnter={() => updateCursor('blue')} />
							<img
								src={TranscendBackground}
								className={Styles.multiplePlatformsBackground}
								alt="Multiple Platforms"
								width="95%"
								loading="lazy" />
						</div>
					} />
				<TwoColumnGrid
					gridStyles={`${Styles.paddedContainer} ${Styles.adminConsoleGridContainer}`}
					revertOnMobile={true}
					leftColumn={
						<img
							src={AdminConsole}
							alt="Robust Admin Console"
							onMouseEnter={() => updateCursor('blue')}
							onMouseLeave={() => updateCursor('white')}
							width="100%"
							height="100%"
							loading="lazy" />
					}
					rightColumn={
						<article className={`${Styles.textSection} py-lg-4`}>
							<h4 className="font-weight-bold">{adminConsole.title}</h4>
							<p className="mt-3">{adminConsole.paragraphOne}</p>
							<p className="mt-3">{adminConsole.paragraphTwo}</p>
						</article>
					} />
				<div onMouseEnter={() => updateCursor('white')} className={Styles.collageContainer}>
					<ImageSwiper
						src={Collage}
						alt="Rekd Collage" />
				</div>
				<CtaSection planetImage={PlanetRekd} />
			</main>
		);
	}
}

export { RekdScreen };
